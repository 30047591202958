import './assets/css/bootstrap.min.css';
import './assets/css/style.scss';

import React, { useState, useEffect } from 'react';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Login from './pages/Login';
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { clearCurrentProfile } from './actions/profileActions';
import Courses from './pages/Courses';
import Certificates from './pages/Certificates';
import LessonDetail from './pages/LessonDetail';
import LessonList from './pages/LessonList';

import { url } from './actions/config'
import {
  getProfile,
  updateProfile
} from './actions/profileActions'

import {
  lightTheme,
  RainbowKitProvider
} from "@rainbow-me/rainbowkit";
import { chains } from './hooks/wagmi';
import jwt from 'jsonwebtoken';
import { disconnect } from '@wagmi/core'



//
// if (localStorage.jwtToken) {
//   setAuthToken(localStorage.jwtToken);
//   const decoded = jwt_decode(localStorage.jwtToken);
//   store.dispatch(setCurrentUser(decoded));
//
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(logoutUser());
//     store.dispatch(clearCurrentProfile());
//     window.location.href = '/login';
//   }
// }

const token = window.localStorage.getItem('web3_japan_token')
if (token) {
  setAuthToken(token)
}


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const [profileChanged, setProfileChanged] = useState(false)



  const getUpdatedProfile = async () => {
    if (!isLoggedIn) {
      setProfilePic(null)
      return
    }

    try {
      const response = await getProfile()
      const profile = response?.data?.data
      // console.log('profile', profile);
      if (profile?.profile_pic) {
        // console.log('got profile pic');
        setProfilePic(url() + profile?.profile_pic)
      }
      else {
        setProfilePic(null)
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getUpdatedProfile()
  }, [isLoggedIn, profileChanged])


  return (
      <BrowserRouter>
        <Switch>
          <Layout profilePic={profilePic}>
            <Route exact path="/">
              <Dashboard
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Route>
            <Route exact path="/profile">
              <Profile
                profileChanged={profileChanged}
                setProfileChanged={setProfileChanged}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Route>
            <Route exact path="/courses">
              <Courses
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Route>
            <Route exact path="/course/:id/lessons">
              <LessonList />
            </Route>
            <Route exact path="/course/lesson/:id">
              <LessonDetail />
            </Route>
            <Route exact path="/certificates">
              <Certificates 
               isLoggedIn={isLoggedIn}
               setIsLoggedIn={setIsLoggedIn}
              />
            </Route>
            <Route exact path="/login">
              <Redirect to="/dashboard" />
            </Route>
          </Layout>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
