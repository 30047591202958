import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  connectorsForWallets
} from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  trustWallet
} from '@rainbow-me/rainbowkit/wallets';
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import {
  goerli,
  mainnet,
  polygon,
  polygonMumbai
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { env } from "../actions/config"
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

let allowedNetworks, alchemyId, projectId;

if (env == 'prod') {
  alchemyId = process.env.REACT_APP_ALCHEMY_PROD_ID;
  projectId = process.env.REACT_APP_PROD_PROJECT_KEY;
  allowedNetworks = [polygon , mainnet]
} else {
  alchemyId = process.env.REACT_APP_ALCHEMY_ID;
  projectId = process.env.REACT_APP_PROJECT_KEY;
  allowedNetworks = [polygonMumbai , polygon , goerli , mainnet]
}


export const { chains, publicClient, webSocketPublicClient } = configureChains(
  allowedNetworks,
  [
    alchemyProvider({ apiKey: alchemyId }), publicProvider()
  ]
);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'web3japan'
    //   }
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId
      }
    })
  ],
  publicClient,
  webSocketPublicClient
})

// const { connectors } = getDefaultWallets({
//   appName: 'Web3Japan',
//   projectId: process.env.REACT_APP_PROJECT_KEY,
//   chains
// });

// export const connectors = connectorsForWallets([
//   {
//     groupName: 'Recommended Web3 Wallets',
//     wallets: [
//       injectedWallet({ chains }),
//       metaMaskWallet({ projectId, chains}),
//       coinbaseWallet({ projectId, chains }),
//       walletConnectWallet({ projectId, chains }),
//       trustWallet({ projectId, chains })
//     ]
//   }
// ]);
