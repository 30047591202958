import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { url } from '../actions/config'
import axios from 'axios'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
  Image
} from '@react-pdf/renderer';
import setAuthToken from "../utils/setAuthToken";
import { tokenChecker } from "../utils/tokenChecker";
import {parse} from 'html-parse-stringify2';



const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: 20,
    textAlign: 'center',
    backgroundColor: '#fff'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    flexGrow: 1,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#787878',
    borderWidth: '5pt'
  },
  text: {
    marginTop: 8,
    marginBottom: 8
  }
});

const tagStyles = {
  h1: {
    fontSize: '40pt'
  },
  h2: {
    fontSize: '32pt'
  },
  h3: {
    fontSize: '28pt'
  },
  h4: {
    fontSize: '24pt'
  },
  h5: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '16pt'
  },
  strong: {
    fontFamily: 'Helvetica-Bold'
  }
}


const parseHTML = (html) => {
  if (!html?.trim()) return []

  const cleanHTML = html.replaceAll('&nbsp;', '')

  let parsed = []
  let consume_tag = false
  let consume_content = false
  let current_tag = ''
  let current_content = ''

  cleanHTML.split('\r\n').forEach(line => {
    line.split('').forEach(char => {
      if (char == '>') {
        consume_tag = false
        consume_content = true
        if (current_tag) {
          parsed.push({
            type: 'tag',
            content: current_tag
          })
          current_tag = ''
        }
      }
      else if (char == '<') {
        consume_tag = true
        consume_content = false
        if (current_content) {
          parsed.push({
            type: 'content',
            content: current_content
          })
          current_content = ''
        }
      }
      else {
        if (consume_tag) {
          current_tag += char
        }
        if (consume_content) {
          current_content += char
        }
      }
    })
  });

  return parsed
}

const getParsedHTMLLines = (html) => {
  const parsed = parse(html);
  const lines = [];

  const traverse = (node, parentTags, prevLineHasStyle) => {
    if (node.type === 'text') {
      const content = node.content.trim();
      if (content) {
        const currentLine = { content, tags: parentTags };
        if (!prevLineHasStyle || parentTags.length > 0) {
          lines.push(currentLine);
        } else {
          const lastLine = lines[lines.length - 1];
          lastLine.content += ' ' + currentLine.content;
        }
        prevLineHasStyle = parentTags.length > 0;
      }
    } else if (node.type === 'tag') {
      const tags = parentTags.concat(node.name);
      if (node.name === 'img') {
        // Handle image tag
        const src = node.attrs.src;
        const alt = node.attrs.alt || '';
        const currentLine = { content: <Image src={src} alt={alt} style={{ width: 150, height: 150 }} />, tags };
        if (!prevLineHasStyle) {
          lines.push(currentLine);
        } else {
          const lastLine = lines[lines.length - 1];
          lastLine.content = <>{lastLine.content} {currentLine.content}</>;
        }
        prevLineHasStyle = false;
      } else {
        node.children.forEach(child => traverse(child, tags, prevLineHasStyle));
        prevLineHasStyle = false;
      }
    }
  };
  

  parsed.forEach(node => traverse(node, []));

  return lines;
};



// Create Document Component
const Certificate = ({ html }) => {
  const lines = getParsedHTMLLines(html)


  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View style={styles.section}>
          {lines.map((line, index) => {
            // const lineStyle = (line.tags || []).reduce((s, tag) => {
            //   return { ...s, ...(tagStyles[tag] || {}) }
            // }, {})
            const lineStyle = (line.tags || []).map(tag => tagStyles[tag] || {})

            return (
              <Text style={[styles.text, { fontFamily: 'Helvetica' }, ...lineStyle]} key={index}>{line.content}</Text>
            )
          })}
        </View>
      </Page>
    </Document>
  )
}





export default function Certificates({  isLoggedIn, setIsLoggedIn }) {

  const [isLoading, setIsLoading] = useState(true);
  const [myCertificates, setMyCertificates] = useState([])
  const [pdfs, setPDFs] = useState({})
  const pdfs_ref = useRef({})
  const [showingCertificate, setShowingCertificate] = useState(null)
  const [error, setError] = useState(null)
  const [is401, setIs401] = useState(false)

  const getMyCertificates = () => {
    axios.post(url() + 'api/certificates/myCertificates', {}).then(response => {
      if (response?.status == 200) {
        const certificatesData = response?.data?.data || []
        setMyCertificates(certificatesData)
        certificatesData.forEach((certificate, index) => {
          getPDFSource(certificate?._id)
        });
      }
      setIsLoading(false)
    }).catch(error => {
      console.error(error);
      if (error?.response?.status == 401) {
        setIs401(true)
      }
      setError(error?.response?.data?.msg)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    const storedToken = window.localStorage.getItem('web3_japan_token');
    const isTokenActive = tokenChecker(storedToken);
    if(isTokenActive && storedToken){
    getMyCertificates()
    } else {
      setAuthToken(undefined)
      setIsLoggedIn(false)
      setIs401(true)
      setIsLoading(false)
    }
  }, [])

  const waitForElement = (id) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        return resolve(document.getElementById(id))
      }

      const observer = new MutationObserver((mutationList, observer) => {
        if (document.getElementById(id)) {
          resolve(document.getElementById(id))
          observer.disconnect()
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true
      })
    })
  }


  const getPDFSource = (id) => {
    waitForElement(id).then(pdf => {
      window.setTimeout(() => {
        setPDFs({ ...pdfs_ref.current, [id]: pdf?.src })
        pdfs_ref.current[id] = pdf?.src
      }, 100)
    })
  }


  const openCertificate = (id) => {
      window.open(pdfs[id], '_blank').focus();
  }



  return !isLoading && (
    <div className="certificate-view">
      {is401 ? (
        <div className="col-12 rounded bg-light mt-3 p-4">
          <p className="mb-0 text-center">Please <Link to="/dashboard">login</Link> to continue.</p>
        </div>
      ) : <></>}

      {!is401 && !myCertificates?.length ? (
        <div className="bg-light p-4 rounded">
          <p>You have no certificates.</p>
        </div>
      ) : (
        <>
          <h5 className="secondary-text text-center mb-4">My Certificates</h5>
          <div className="row " >
              <div className="col-md-12">
                <div className="card-columns">
            {myCertificates.map((certificate, index) => {
              return (
                // <div className="certificate bg-light_2 m-2 p-4 card-demo" key={index}>
                //   <h4 className="primary-text mb-4">{certificate.course_name}</h4>
                //   <div className="d-inline-flex btn-group category">
                //     <p>{certificate.category_name}</p>
                //   </div>
                //   <div className="d-flex mt-auto">
                //     <button
                //       className="btn btn-primary"
                //       onClick={() => openCertificate(certificate?._id)}
                //     >View Certificate</button>
                //   </div>
                // </div>
                
                    <div className="card bg-light_2" key={index}>
                      <a href="#">
                        <div className="card-body ">
                          <h5 className="card-title">{certificate.course_name}</h5>
                          <p className="card-text category1">
                          {certificate.category_name}
                          </p>
                          
                          <p>
                            <button
                              className="btn btn-primary"
                              onClick={() => openCertificate(certificate?._id)}
                            >View Certificate</button>
                          </p>
                        </div>
                      </a>
                    </div>
                    
              )
            })}
                </div>
              </div>
            </div>
        </>
      )}

      {showingCertificate ? (
        <div className="view-certificate-container">
          <div className="view-certificate">
            <div className="certificate-border">
              <div
                className="certificate-content"
                dangerouslySetInnerHTML={{ __html: showingCertificate.certificate }}
              ></div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-secondary"
                onClick={() => setShowingCertificate(null)}
              >Close</button>
            </div>
          </div>
        </div>
      ) : <></>}

      {myCertificates.map((certificate, index) => {
        return (
          <div className="d-none" key={certificate?._id}>
            <PDFViewer id={certificate?._id}>
              <Certificate html={certificate?.certificate} />
            </PDFViewer>
          </div>
        )
      })}
    </div>
  );
}
