import React, { useState, useEffect, useCallback } from "react";
import CourseCard from "../components/CourseCard";
import blockchain from "../assets/images/blockchain.jpg";
import nft from "../assets/images/nft.jpeg";
import web3 from "../assets/images/web3.jpg";
import cover from "../assets/images/cover.jpg";
import { TbPlus } from "react-icons/tb";
import { Link, useHistory } from "react-router-dom";
import { url } from '../actions/config'
import axios from 'axios'
import { debounce } from "lodash"
import { chain, useAccount, useNetwork } from 'wagmi';
import setAuthToken from "../utils/setAuthToken";
import { tokenChecker } from "../utils/tokenChecker";


export default function Courses({ isLoggedIn, setIsLoggedIn }) {

  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([])
  const [myCourses, setMyCourses] = useState([])
  const [categories, setCategories] = useState([])
  const [error, setError] = useState('')
  const [search, setSearch] = useState("");
  const [is401, setIs401] = useState(false)
  const [displayedCourses, setDisplayedCourses] = useState([]);
  const [coursesPerPage, setCoursesPerPage] = useState(10);
  const { isConnected, isDisconnected, address } = useAccount();
  const { chain: currentChain } = useNetwork();
  const getIsSigned = localStorage.getItem('isSigned');

  const history = useHistory()

  const getCourses = (searched_course) => {
    let filter = {};
    if (!searched_course || searched_course === "") {
      filter = { displaySearch: "" }
    } else {
      filter = { displaySearch: searched_course }
    }
    axios.post(url() + 'api/courses', filter).then(response => {
      if (response?.status == 200) {
        setCourses(response?.data?.data)
        setDisplayedCourses(response?.data?.data.slice(0, coursesPerPage));
      }
      setIsLoading(false)
    }).catch(error => {
      console.error(error);
      if (error?.response?.status == 401) {
        setIs401(true)
      }
      setError(error?.response?.data?.msg)
      setIsLoading(false)
    })
  }

  function searchChange(text) {
    setSearch(text);
    if (text === "") {
      getCourses("");
    }
  }

  const searchQuery = useCallback(debounce(() => {
    getCourses(search)
  }, 400), [search]);

  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);


  const getCategories = () => {
    axios.post(url() + 'api/categories', {}).then(response => {
      if (response?.status == 200) {
        setCategories(response?.data?.data || [])
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getMyCourses = (id) => {
    axios.post(url() + 'api/courses/mycourses', {}).then(response => {
      if (response?.status == 200) {
        setMyCourses(response?.data?.data)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const startCourse = (id) => {
    axios.post(url() + 'api/courses/start', { course_id: id }).then(response => {
      if (response?.status == 200) {
        getMyCourses()
        history.push(`/course/${id}/lessons`)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    const storedToken = window.localStorage.getItem('web3_japan_token');
    const isTokenActive = tokenChecker(storedToken);
    if (isTokenActive) {
      getCourses()
      getMyCourses()
      getCategories()
    } else {
      setAuthToken(undefined)
      setIsLoggedIn(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (bottom) {
      setTimeout(() => {
        setDisplayedCourses((prevCourses) =>
          [...prevCourses, ...courses.slice(coursesPerPage, coursesPerPage + 10)]
        );
        setCoursesPerPage((prevCoursesPerPage) => prevCoursesPerPage + 10);
      }, 100); // Add a delay of 100ms before adding the new courses
    }
  };



  return !isLoading && (
    <div className="course-view">
      <div className="row mt-2">
        <div className="col-xl-4  col-md-10 col-sm-10">
          <input type="text" value={search} onChange={(e) => searchChange(e.target.value)} className='form-control fm_bg p-1' placeholder="Search Courses..." />
        </div>
      </div>
      <div className="row"> 
       {/* <div className="card_box">  */}
        {is401 ? (
          <div className="col-12 rounded bg-light_2 mt-3 p-4 text-center mx-auto">
            <p className="mb-0">Please <Link to="/dashboard">login</Link> to continue.</p>
          </div>
        ) : <></>}

        {!is401 && !courses?.length ? (
          <p className="w-100 m-5 text-center text-secondary">There are no courses.</p>
        ) : <></>}


        {displayedCourses.map((data, index) => (
          // <div className="col-md-4 mb-3 mx-auto" key={index} style={{float:"left"}}>

          <div 
            // className="card-demo" 
            className="col-xl-4 col-lg-6 col-md-12 mt-3 mb-3"
            key={index} >
            <CourseCard
              courseId={data._id}
              image={data.banner_image}
              title={data.name}
              category={data.category_id?.name}
              text={data.description}
              numLesson={data.lessons_count}
              isActive={data.status}
              purchased={data.purchased}
              startCourse={startCourse}
              myCourses={myCourses}
            />
          </div>

        ))}
      </div>
    </div>
  );
}
