import React, { useEffect, useState } from "react";
import LessonCard from "../components/LessonCard";
import { TbPlus } from "react-icons/tb";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import axios from 'axios'
import { preciseDecimal } from '../utility'




export default function LessonList() {

  const [course, setCourse] = useState({})
  const [myCourses, setMyCourses] = useState([])
  const [error, setError] = useState('')

  const { id } = useParams()
  const history = useHistory()

  const getCourseDetails = () => {
    axios.get(url() + 'api/courses/' + id).then(response => {
      if (response?.status == 200) {
        const course = response?.data?.data?.[0]
        setCourse(course)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getMyCourses = (id) => {
    axios.post(url() + 'api/courses/mycourses', {}).then(response => {
      if (response?.status == 200) {
        setMyCourses(response?.data?.data)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    getCourseDetails()
    getMyCourses()
  }, [])

  const myCourse = myCourses.find(c => c.course_id._id == course._id)


  return (
    <div style={{paddingTop: "100px"}} className="lesson-card-view">
      
      <div>

        <button
          className="position-absolute d-flex align-items-center mt-2 ml-3 mr-4 mb-5 btn btn-secondary"
          onClick={() => history.push('/courses')}
        ><BiArrowBack className="back-arrow" /></button><br/><br/>
        <div className="clearfix"> </div>

        {!course?.lessons?.length ? (
          <p className="text-white">There are no lessons for this course.</p>
        ) : <></>}

        <h5 className="mt-3 mb-4 text-center primary-text font-weight-bold">{course?.name}</h5>
        <h3 className="mt-3 mb-4 text-center description-text ">{course?.description}</h3>
        {myCourse ? (
          <div className="mx-auto" style={{ maxWidth: '400px' }}>
            <div className="mx-5 mb-5">
              <h6>{preciseDecimal(myCourse.completed_percent, 1, 0) || 0}% Complete</h6>
              <div
                className="progress"
                style={{ height: "10px" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${myCourse.completed_percent || 0}%`,
                    backgroundColor: myCourse.completed_percent == 100 ? '#60e277' : ''
                  }}
                  aria-valuenow={myCourse.completed_percent || 0}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        ) : <></>}

        {(course?.lessons || []).map(lesson => (
          <LessonCard
            course_id={id}
            lesson_id={lesson._id}
            title={lesson.name}
            course_started={!!myCourse}
            completed={lesson.has_completed}
            key={lesson._id}
          />
        ))}
      </div>
    </div>
  );
}
