import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import store from "./store";
import Web3 from 'web3'
import { wagmiConfig } from "./hooks/wagmi";
import { WagmiConfig } from "wagmi";


function getLibrary(provider, connector) {
  return new Web3(provider)
}


ReactDOM.render(

  // <React.StrictMode>
  <WagmiConfig config={wagmiConfig}>
      <Provider store={store}>
          <App />
      </Provider>
  </WagmiConfig>,
  // </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
