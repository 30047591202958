import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CourseCard from "../components/CourseCard";
import cover from "../assets/images/cover.jpg";
import ConnectMetaMask from "../components/ConnectMetaMask";
import { formatAddress } from "../web3/helpers/format-string";
import BlockchainLogo from "../assets/images/blockchain.jpg";
import { FiCheckCircle } from "react-icons/fi";
import { TbCertificate } from "react-icons/tb";
import { url } from '../actions/config'
import axios from 'axios'
import { chain, useAccount, useNetwork  } from 'wagmi';
import { tokenChecker } from "../utils/tokenChecker";
import setAuthToken from "../utils/setAuthToken";

export default function Dashboard({  isLoggedIn, setIsLoggedIn }) {

  // const { connected, address } = useWeb3Context();
  const [stats, setStats] = useState({})
  const [courses, setCourses] = useState({})
  const [myCourses, setMyCourses] = useState([])
  const [error, setError] = useState(null)
  const [signOutWallet , setSignOutWallet] = useState(false)
  const { isConnected, isDisconnected , address  } = useAccount();
  const { chain: currentChain } = useNetwork();

  const getDashboardStats = () => {
    axios.get(url() + 'api/users/stats').then(response => {
      if (response?.status == 200) {
        setStats(response?.data?.data || {})
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getCourses = () => {
    axios.post(url() + 'api/courses').then(response => {
      if (response?.status == 200) {
        const courses = response?.data?.data || []
        let coursesObject = {}
        courses.forEach(course => {
          coursesObject[course._id] = course
        });
        setCourses(coursesObject)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getMyCourses = () => {
    axios.post(url() + 'api/courses/mycourses', {}).then(response => {
      if (response?.status == 200) {
        // const courses = (response?.data?.data || []).filter(course => course?.completed_percent != 100);
        const courses = (response?.data?.data || []);
        setMyCourses(courses)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    const storedToken = window.localStorage.getItem('web3_japan_token');
    // console.log('dashboard stored token : ',storedToken)
    const isTokenActive = tokenChecker(storedToken);

    if(isTokenActive && isConnected && localStorage.isSigned === 'true'){
      getDashboardStats()
      getCourses()
      getMyCourses()
    } else {
      setAuthToken(null)
      setIsLoggedIn(false)
      setSignOutWallet(true)
    }
  }, [isConnected , isLoggedIn])


  return (
    <div className="dashboard-view">
      <div className="wallet-connect-box bg-light">
        <ConnectMetaMask
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          signOutWallet = {signOutWallet}
        />
      </div>
      {isConnected && localStorage.isSigned === 'true' ? (
        <>
          <div className="x-home-check">
            <div className="continue-learning-box">
              <h1 className="continue-box-title">Continue Learning</h1>
             </div>
              <div className="row">
                {myCourses.map(course => {
                  return (
                    <div className="col-xl-4 col-lg-6 col-md-12 mt-3 mb-3" key={course._id}>
                      <CourseCard
                        courseId={course?.course_id?._id}
                        image={course?.course_id?.banner_image}
                        title={course?.course_id?.name}
                        category={course?.category_id?.name}
                        text={course?.course_id?.description}
                        numLesson={course?.course_id?.lessons_count}
                        isActive={course?.course_id?.status}
                        purchased={course?.course_id?.purchased}
                        startCourse={() => {}}
                        myCourses={myCourses}
                      />
                    </div>
                  )
                })}
              </div>

              {/*
              <div className="continue-box-content">
                <div>
                  <img src={BlockchainLogo} />
                </div>
                <div className="d-flex flex-column justify-content-between w-100 p-4">
                  <h3 className="continue-learning-course-title">Solidity Basic</h3>
                  <p>Last Active: 2 days ago</p>
                  <div className="row w-100">
                    <div className="col-sm-9 col-md-9">
                      <h6 style={{paddingLeft: "10px"}}>25% Complete</h6>
                      <div
                        className="progress"
                        style={{width: "200px", height: "10px"}}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{width: "25%"}}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              */}
            {/* </div> */}

            <div className="row mt-4">
              {/* <div className="col-12 col-md-4 mb-2">
                <div className="dashbord-data-box basic-card-aqua p-4">
                  <div className="card-heading">
                    <h4 className="text-center mb-4">Nodes</h4>
                  </div>
                  <div className="data-box-value">
                    <h3>{stats?.nodes || 0}</h3>
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-md-6 mb-2">
                <div className="dashbord-data-box basic-card-lips p-4">
                  <div>
                    <h4 className="text-center mb-4">Certificates</h4>
                  </div>
                  <div className="data-box-value">
                    <h3>{stats?.certificates || 0}</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-2">
                <div className="dashbord-data-box basic-card-green p-4">
                  <div>
                    <h4 className="text-center mb-4">Courses Completed</h4>
                  </div>
                  <div className="data-box-value">
                    <h3>{stats?.completedCourses || 0}</h3>
                  </div>
                </div>
              </div>
            </div>

            {/*
            <div className="row mt-4">
              <div className="col-12 col-sm-4">
                <CourseCard
                  image={cover}
                  title="Solidity Basic"
                  text="Learn the ins and out of the Ethereum blockchain. Create a smart contract and create your own DAPP. Coding Experience is required."
                  numLesson={20}
                  purchased={true}
                  isActive={true}
                  nodeId={0}
                  myCourses={[]}
                />
              </div>
            </div>
            */}
          </div>
        </>
      ) : (
        <div className="col-12 rounded bg-light mt-3 p-4">
          { isConnected && !localStorage.isSigned ? (
            <p className="mb-0 text-center">Please Provide Your Signature Sent To Your Wallet.</p>
          ) : (
            <p className="mb-0 text-center">Please login by connecting your Web3 Wallet using the button above.</p>
          ) }
        </div>
      )}
    </div>
  );
}