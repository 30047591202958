import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "../assets/images/avatar.png";
import {
  getProfile,
  updateProfile
} from '../actions/profileActions'
import { url } from '../actions/config'
import setAuthToken from "../utils/setAuthToken";
import { tokenChecker } from "../utils/tokenChecker";



export default function Profile({ profileChanged, setProfileChanged, isLoggedIn, setIsLoggedIn }) {

  const [isLoading, setIsLoading] = useState(true);
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("");
  const [nameStatic, setNameStatic] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [is401, setIs401] = useState(false);
  const [profileData, setProfileData] = useState({})

  const handleSelectImage = (e) => {
    const file = e.target.files[0]
    setAvatar(file)
    setAvatarPreview(URL.createObjectURL(file))
  };

  const onGetProfile = async () => {
    try {
      const response = await getProfile()
      const profile = response?.data?.data
      setProfileData(profile)
      setName(profile.name ? profile?.name : "")
      setNameStatic(profile.name ? profile?.name : "")
      setEmail(profile.email ? profile?.email : "")
      setDescription(profile.description && profile.description !== "undefined" ? profile?.description : "")
      if (profile?.profile_pic) {
        setAvatarPreview(url() + profile?.profile_pic)
      }
      setIsLoading(false)
    }
    catch (error) {
      console.error(error);
      if (error?.response?.status == 401) {
        setIs401(true)
      }
      setError(error?.response?.data?.msg || 'Something went wrong')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const storedToken = window.localStorage.getItem('web3_japan_token');
    const isTokenActive = tokenChecker(storedToken);
    if (isTokenActive && storedToken) {
      onGetProfile()
    } else {
      setAuthToken(undefined)
      setIsLoggedIn(false)
      setIs401(true)
      setIsLoading(false)
    }
  }, [])

  const onUpdateProfile = async () => {
    setSuccess(null)
    setError(null)

    if (name?.trim().length > 25) {
      setError('Name must be 25 characters or less.')
      return;
    }
    if (email?.trim().length > 50) {
      setError('Email must be 50 characters or less.')
      return;
    }
    if (description?.trim().length > 300) {
      setError('About Me must be 300 characters or less.')
      return;
    }

    const isAnyFieldUpdated =
      name !== profileData.name ||
      email !== profileData.email ||
      description !== profileData.description

    if (!isAnyFieldUpdated) {
      setError('At least 1 field is required for updation.');
      return;
    }

    // if (!name || !email || !description) {
    //   setError('Atleast 1 field is required for updation.')
    //   return;
    // }

    let formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('profile_pic', avatar)
    formData.append('description', description)

    try {
      const result = await updateProfile(formData)
      setSuccess('Successfully updated profile.')
      setProfileChanged(!profileChanged)
      window.setTimeout(() => {
        setSuccess(null)
      }, 3000)
    }
    catch (error) {
      console.error(error);
      setError(error?.response?.data?.msg || 'Something went wrong.')
      window.setTimeout(() => {
        setError(null)
      }, 3000)
    }
  }

  return !isLoading && (
    <div className="profile-container px-3">
      {is401 ? (
        <div className="col-12 rounded bg-light mt-3 p-4">
          <p className="mb-0 text-center">Please <Link to="/dashboard">login</Link> to continue.</p>
        </div>
      ) : (
        <div className="profile-view">
          <div className="row align-items-center">
            <div className="col-12 col-sm-auto mb-3">
              <label htmlFor="upload-avatar">
                <div className="file-dragger">
                  {avatarPreview ? (
                    <img src={avatarPreview} className="profile-avatar" alt="avatar" />
                  ) : (
                    <img src={Avatar} className="profile-avatar" alt="avatar" />
                  )}
                </div>
              </label>
              <input
                id="upload-avatar"
                type="file"
                accept="image/png,image/jpg,image/jpeg"
                autoComplete="off"
                tabIndex={-1}
                style={{ display: "none" }}
                onChange={handleSelectImage}
              />
            </div>
            <div className="col-12 col-sm-auto mb-3">
              <div className="avatar-title d-flex flex-column justify-content-around h-100">
                <p>{nameStatic}</p>
                <button className="btn btn-secondary" onClick={() => document.getElementById('upload-avatar').click()}>Upload Avatar</button>
              </div>
            </div>
          </div>

          <div className="setting-box row">
            <div className="col-12 col-sm-6 mb-3">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={name}
                  onInput={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 mb-3">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="john@gmail.com"
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="form-group">
                <label>About Me</label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder=""
                  value={description}
                  onInput={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            {success ? (
              <p className="col-12 text-success text-center">{success}</p>
            ) : null}

            {error ? (
              <p className="col-12 text-danger text-center">{error}</p>
            ) : null}

            <div className="col-12 d-flex justify-content-center mt-3">
              <button
                className="btn btn-primary-n"
                onClick={onUpdateProfile}
              >Update Profile </button>
            </div>
          </div>
        </div>

      )}
    </div>
  );
}
