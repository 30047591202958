import React, { useEffect, useState } from "react";
import { MdOutlineNavigateBefore } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { FiCircle, FiCheckCircle } from "react-icons/fi";
import { BiArrowBack } from "react-icons/bi";
import { useParams, useHistory, Link } from 'react-router-dom'
import { url } from '../actions/config'
import { Editor ,EditorState, convertFromRaw  ,convertT} from 'draft-js';
import axios from 'axios'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';




const LessonDetail = () => {

  const [lesson, setLesson] = useState({})
  const [contentType, setContentType] = useState('text')
  const [lessonContent, setLessonContent] = useState(EditorState.createEmpty());
  const [myCourses, setMyCourses] = useState([])
  const [completedLessons, setCompletedLessons] = useState([])
  const [error, setError] = useState('')
  const { id } = useParams()

  const history = useHistory()

  const getLessonDetails = () => {
    axios.get(url() + 'api/lessons/' + id).then(response => {
      if (response?.status == 200) {
        const lesson = response?.data?.data
        setLesson(lesson || {})
        setContentType(lesson?.content_type || 'text')
        // if (lesson?.content_type != 'video') {
        //   document.getElementById('lesson').innerHTML = lesson?.content || ''
        // }
        if (lesson?.content_type != 'video') {
          setLessonContent(lesson?.content || '')
        }
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg || 'Problem fetching lesson')
    })
  }


  const getMyCourses = (id) => {
    axios.post(url() + 'api/courses/mycourses', {}).then(response => {
      if (response?.status == 200) {
        const courses = response?.data?.data || []
        setMyCourses(courses)
        setCompletedLessons(courses.reduce((completed, course) => {
          return [...completed, ...(course.completed_lessons.map(lesson => lesson.lesson_id) || [])]
        }, []))
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const toggleLessonCompleted = () => {
    axios.put(url() + 'api/lessons/completed/' + id).then(response => {
      if (response?.status == 200) {
        getMyCourses()
        window.scrollTo({ top: 0 })
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
    getLessonDetails()
    getMyCourses()
  }, [])

  useEffect(() => {
    // console.log('completedLessons', completedLessons);
  }, [completedLessons])


  const isLessonCompleted = completedLessons.find(lesson_id => lesson_id == lesson._id)
  const myCourse = myCourses.find(course => course?.course_id == lesson?.course_id)
  const isCourseCompleted = myCourse?.is_completed
  // console.log('myCourse', myCourse);
  // console.log('isCourseCompleted', isCourseCompleted);

  // var config = {
  //   modules: {
  //       toolbar: false,
  //       syntax: true
  //   },
  //   "theme": "bubble",
  // };

 
  return (
    <div className="lesson-view-container">
      <div className="lesson-detail-container">
        <div className="w-100 flex flex-column" style={{zIndex: 10}}>
          <button
            className="position-absolute d-flex align-items-center mt-2 btn btn-secondary"
            onClick={() => lesson?.course_id ? history.push(`/course/${lesson?.course_id?._id}/lessons`) : history.goBack()}
          ><BiArrowBack className="back-arrow" /></button>

          <div className="lesson-title d-flex">
            <p className="mb-0 mr-2">{lesson?.sequence_number ?
              <span className="text-danger mr-3">{lesson.sequence_number}.</span> : <></>}
              {lesson?.name || '...'}</p>
            {lesson?._id ? (
              isLessonCompleted ? (
                <FiCheckCircle color='#10c65f' />
              ) : (
                <FiCircle color='gray' />
              )
            ) : <></>}
          </div>

          {error ? (
            <p className="text-center text-danger">{error}</p>
          ) : <></>}

          <div className={`lesson-view ${contentType == 'video' ? 'video' : ''}`}>
            {contentType == 'video' ? (
              <video width="100%" controls style={{ height:'600px' }}>
                <source src={url() + 'course_videos/' + lesson?.videoPath} />
              </video>
            ) : (
              <div id="lesson">
                  <ReactQuill value={lessonContent} readOnly={true} theme='bubble'/>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center w-100 mb-5">
        {lesson?._id ? (
          <button
            style={{marginTop : '20px'}}
            className={`btn btn-${isLessonCompleted ? 'secondary' : 'primary'}`}
            onClick={toggleLessonCompleted}
            disabled={isCourseCompleted}
          >{isLessonCompleted ? 'Mark as Incomplete' : 'Mark as Complete'}</button>
        ) : <></>}
      </div>

      {/*
      <div className="next-lesson-button">
        <MdNavigateNext
          style={{width: "30px", height: "30px", marginLeft: "30px"}}
        />
      </div>
      */}
    </div>
  );
};

export default LessonDetail;
